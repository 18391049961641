import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './about.module.css';
import mission from '../../images/mission.svg';
import vision from '../../images/vision.svg';
import values from '../../images/values.svg';
import union from '../../images/Union.png';

function About() {
  const { t } = useTranslation();
  return (
    <div className='container'>
      <div className={style.about__wrapper} id='about'>
        <div className={style.about}>
          <div className={style.about__title}>
            <div className={style.about__img}>
              <img src={union} alt="children" />
            </div>
            <div className={style.content__wrapper}>
              <div className={style.about__title__text}>
                <h4>{t('about.h4')}</h4>
                <h2 className='who'>{t('about.h2')}</h2>
                <p>{t('about.p')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={style.about__info}>
          <div className={style.about__details}>
            <img src={mission} alt="target icon" />
            <div className={style.about__details__text}>
              <h3>{t('about.h31')}</h3>
              <p>{t('about.p1')}</p>
            </div>
          </div>
          <div className={style.about__details}>
            <img src={vision} alt="eye" />
            <div className={style.about__details__text}>
              <h3>{t('about.h32')}</h3>
              <p>{t('about.p2')}</p>
            </div>
          </div>
          <div className={style.about__details}>
            <img src={values} alt="heart icon" />
            <div className={style.about__details__text}>
              <h3>{t('about.h33')}</h3>
              <p>{t('about.p3')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About;
