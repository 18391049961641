import React, { useState, useEffect } from 'react'
import style from './testimonies.module.css';
import { useTranslation } from 'react-i18next';
import mariam from '../../images/unsplash_i2hoD-C2RUA.png';
import ncc from '../../images/mo.svg';
import sministry from '../../images/ministry.png';
import greenquoteleft from '../../images/greenquoteleft.svg';
import greenquoteright from '../../images/greenquoteright.svg';

function Testimonials() {
  const { t } = useTranslation();
  const [dotActive, setDotActive] = useState('');

  // uncoment lines to add the logic of more than one testimonials 
  useEffect(() => {
    // swipe('dot2');
  }, []);

  const onScrollHandler = (e) => {
    // const carousel = document.getElementById('review_carousel');
    // const card2 = document.getElementById('rev2');;
    // const card3 = document.getElementById('rev3');
    // const card2Left = card2.offsetLeft;
    // const card3Left = card3.offsetLeft;
    // const carouselMidWidth = carousel.scrollLeft + (carousel.innerWidth / 2);
    // if (carouselMidWidth < card2Left) {
    //   setDotActive('dot1');
    // } else if (carouselMidWidth < card3Left) {
    //   setDotActive('dot2');
    // } else if (carouselMidWidth > card3Left) {
    //   setDotActive('dot3');
    // }
  }

  const swipe = (dotNumber) => {
    const carousel = document.getElementById('review_carousel');
    const secondItem = document.getElementById('rev2');
    setDotActive(dotNumber)

    switch (dotNumber) {
      case 'dot1':
        // The beginning of carousel
        carousel.scrollLeft = 0;
        break;
      case 'dot2':
        secondItem.scrollIntoView({ inline: 'center', block: 'nearest' }) //inline: 'center' because it's a horizontal scroll, block for vertical.
        break;
      case 'dot3':
        // The end of carousel
        carousel.scrollLeft = carousel.scrollWidth;
        break;
      default:
        break;
    }
  }

  return (
    <>
      <div className='container'>
        <div className={style.testimonial__wrapper}>
          <div className={style.testimonials}>
            <div className={style.testimonials__text}>
              <h4>{t('testimonies.h4')}</h4>
              <h2>{t('testimonies.h2')}</h2>
              <p>{t('testimonies.p')}</p>
            </div>
            <div className={style.reviews}>
              <div onScroll={onScrollHandler} id="review_carousel" className={style.review_carousel}>
                <div className={style.review}>
                  <div className={style.greenleft}>
                    <img src={greenquoteleft} alt="" />
                  </div>
                  <div className={style.user}>
                    <img src={mariam} alt="user" loading="lazy" />
                    <p className={style.bold}>{t('testimonies.userName')}</p>
                  </div>
                  <p>{t('testimonies.userP')}</p>
                  <div className={style.greenright}>
                    <img src={greenquoteright} alt="" />
                  </div>
                </div>
                {/* <div className={style.review} id="rev2">
                  <div className={style.greenleft}>
                    <img src={greenquoteleft} alt="" />
                  </div>
                  <div className={style.user}>
                    <img src={mariam} alt="user" loading="lazy" />
                    <p className={style.bold}>{t('testimonies.userName')}</p>
                  </div>
                  <p>{t('testimonies.userP')}</p>
                  <div className={style.greenright}>
                    <img src={greenquoteright} alt="" />
                  </div>
                </div> */}
                {/* <div className={style.review} id="rev3">
                  <div className={style.greenleft}>
                    <img src={greenquoteleft} alt="" />
                  </div>
                  <div className={style.user}>
                    <img src={mariam} alt="user" loading="lazy" />
                    <p className={style.bold}>{t('testimonies.userName')}</p>
                  </div>
                  <p>{t('testimonies.userP')}</p>
                  <div className={style.greenright}>
                    <img src={greenquoteright} alt="" />
                  </div>
                </div> */}
              </div>
              <div className={style.steps}>
                {/* <span className={`${style.step} ${dotActive === 'dot1' ? style.active : ''}`} onClick={() => swipe('dot1')} /> */}
                {/* <span className={`${style.step} ${dotActive === 'dot2' ? style.active : ''}`} onClick={() => swipe('dot2')} />
                <span className={`${style.step} ${dotActive === 'dot3' ? style.active : ''}`} onClick={() => swipe('dot3')} /> */}
              </div>
            </div>
          </div>
        </div>
        <div className={style.affiliations__wrapper}>
          <div className={style.affiliations}>
            <h3>{t('testimonies.h3')}</h3>
            <div className={style.affiliations_logos}>
              <img src={sministry} className={style.grayscale} alt="ministry of health" />
              <img src={ncc} className={style.grayscale} alt="ncc" />
            </div>
          </div>
        </div>
      </div>
      <div className={style.features__colors}>
        <div className={style.green}></div>
        <div className={style.yellow}></div>
        <div className={style.orange}></div>
        <div className={style.red}></div>
      </div>
    </>
  )
}

export default Testimonials;
