import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './contact.module.css';

function Contact() {

  const [captchaDone, setCaptchaDone] = useState(false);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [checked, setChecked] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [captcha, setCaptcha] = useState('');
  const mutationRef = useRef(captchaDone);
  const { t } = useTranslation();

  useEffect(() => {
    mutationRef.current = captchaDone;
  }, [captchaDone]);

  const handleChange = e => {
    if (e.target.name === 'fullName') {
      setFullName(e.target.value);
    } else if (e.target.name === 'email') {
      setEmail(e.target.value);
    } else if (e.target.name === 'subject') {
      setSubject(e.target.value);
    } else if (e.target.name === 'description') {
      setDescription(e.target.value);
    } else if (e.target.name === 'terms') {
      setChecked(!checked);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const url = "https://7obo5njmwl.execute-api.eu-west-3.amazonaws.com/Deploy/contact-us";
    const myData = {
      contactName: fullName,
      contactEmail: email,
      contactSubject: subject,
      contactDescription: description
    }

    if (fullName && email && subject && description && mutationRef.current) {
      axios({
        method: 'post',
        url: url,
        data: myData
      })
        .then(() => {
          alert("Email sent.");
          setFullName('');
          setEmail('');
          setSubject('');
          setDescription('');
          setCaptchaDone(false);
          setHasError(false);
          captcha.reset();
          setChecked(false)
        })
        .catch(err => alert(err));
    } else {
      setHasError(true)
    }
  }

  function onChange() {
    mutationRef.current = true;
    setCaptchaDone(mutationRef.current)
  }

  function handleExpire() {
    setCaptchaDone(false)
    console.log("Verification has expired, re-verify.")
  }

  function handleError() {
    setCaptchaDone(false)
    console.log("Something went wrong, check your conenction")
  }

  return (
    <div className={styles.contact} id='contact'>
      <div className={styles.headers}>
        <h4>{t('contact.h4')}</h4>
        <h2>{t('contact.h2')}</h2>
        <p>{t('contact.p')}</p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={styles.formContent}>
          <div className={styles.formUpperContent}>
            <label>
              <p className='bold'>
                {t('contact.name')}
                {!fullName && <span className={styles.starValidation}> *</span>}
              </p>
              <input type="text" placeholder={t('contact.name-placeholder')} value={fullName} name='fullName' onChange={handleChange} className={hasError && !fullName ? `${styles.formContent_input} ${styles.input_error}` : styles.formContent_input} />
            </label>
            <label>
              <p className='bold'>
                {t('contact.email')}
                {!email && <span className={styles.starValidation}> *</span>}
              </p>
              <input type="email" placeholder={t('contact.email-placeholder')} value={email} name='email' onChange={handleChange} className={hasError && !email ? `${styles.formContent_input} ${styles.input_error}` : styles.formContent_input} />
            </label>
          </div>
          <label>
            <p className='bold'>
              {t('contact.subject')}
              {!subject && <span className={styles.starValidation}> *</span>}
            </p>
            <input type="text" placeholder={t('contact.subject-placeholder')} value={subject} name='subject' onChange={handleChange} className={hasError && !subject ? `${styles.formContent_input} ${styles.input_error}` : styles.formContent_input} />
          </label>
          <label>
            <p className='bold'>
              {t('contact.description')}
              {!description && <span className={styles.starValidation}> *</span>}
            </p>
            <textarea type="text" placeholder={t('contact.description-placeholder')} value={description} name='description' onChange={handleChange} className={hasError && !description ? `${styles.formContent_textarea} ${styles.input_error}` : styles.formContent_textarea} />
          </label>
        </div>
        <input type="checkbox" name="terms" checked={checked} onChange={handleChange} className={styles.checkbox} />
        <label className={styles.formPolicy} htmlFor="terms">{t('contact.terms')} <Link target="_blank" to='/policy'>{t('footer.li2')}</Link>.</label>
        <div className={styles.recaptcha}>
          <ReCAPTCHA
            ref={e => (setCaptcha(e))}
            sitekey={'6Ld0howkAAAAAMY-F2dXtlTKBen82cx4p9G-sqo-'}
            onChange={onChange}
            onExpired={handleExpire}
            onErrored={handleError}
          />
        </div>
        <button className={!captchaDone || !fullName || !email || !subject || !description || !checked ? styles.submitBtndisabled : styles.submitBtn} type='submit'>{t('contact.formSubmit')}</button>
      </form>
    </div>
  )
}

export default Contact;
