import React from 'react'
import banner from '../../images/banner.svg';
import style from './home.module.css';
import { useTranslation } from 'react-i18next';

function Home() {
  const { t } = useTranslation();

  return (
    <div className='container'>
      <div className={style.banner} id='home'>
        <div className={style.banner__content}>
          <h4>{t('banner.h4')}</h4>
          <h1>{t('banner.h11')}
            <br />
            <span>{t('banner.h12')}</span>
          </h1>
          <p className={style.banner__p}>{t('banner.p')}</p>
          <div>
            <a href="https://app.kitohealth.org/" target='_blank' rel="noreferrer">
              <button>{t('banner.button')}</button>
            </a>
          </div>
        </div>
        <div className={style.banner__img}>
          <img src={banner} alt="woman and child" />
        </div>
      </div>
    </div>
  )
}

export default Home;
