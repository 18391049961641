import React from 'react';
import { useTranslation } from 'react-i18next';
import mobileHeart from '../../images/mobile-heart.svg';
import mobileQuestion from '../../images/mobile-question.svg';
import lamp from '../../images/lamp.svg';
import safe from '../../images/safe.svg';
import phonePic from '../../images/content-middle-small-screen.svg';
import phonePicLargeScreen from '../../images/content-middle-large-screen.svg';
import styles from './kitoApp.module.css';

function KitoApp() {
  const { t } = useTranslation();

  return (
    <section className={styles.container} id='howItWorks'>
      <div className={styles.headers}>
        <h4>{t('kitoApp.h4')}</h4>
        <h2>{t('kitoApp.h2')}</h2>
        <p>{t('kitoApp.p')}</p>
      </div>
      <div className={styles.content}>
        <div className={styles.phonePic}>
          <picture>
            <source media="(min-width: 1200px)" srcSet={phonePicLargeScreen} />
            <img src={phonePic} alt="phone pic" />
          </picture>
        </div>
        <div className={styles.steps}>
          <div className={styles.stepsLeftRow}>
            <div className={styles.step}>
              <div className={styles.stepHeaders}>
                <img src={mobileHeart} alt='Heart' />
                <div>
                  <h3>{t('kitoApp.h31')}</h3>
                  <p className='bold'>{t('kitoApp.pBold1')}</p>
                </div>
              </div>
              <p className={styles.stepParagraph}>{t('kitoApp.p1')}</p>
            </div>
            <div className={styles.step}>
              <div className={styles.stepHeaders}>
                <img src={mobileQuestion} alt='Question' />
                <div>
                  <h3>{t('kitoApp.h32')}</h3>
                  <p className='bold'>{t('kitoApp.pBold2')}</p>
                </div>
              </div>
              <p className={styles.stepParagraph}>{t('kitoApp.p2')}</p>
            </div>
          </div>
          <div className={styles.stepsRightRow}>
            <div className={styles.step}>
              <div className={styles.stepHeaders}>
                <img src={lamp} alt='lamp' />
                <div>
                  <h3>{t('kitoApp.h33')}</h3>
                  <p className='bold'>{t('kitoApp.pBold3')}</p>
                </div>
              </div>
              <p className={styles.stepParagraph}>{t('kitoApp.p3')}</p>
            </div>
            <div className={styles.step}>
              <div className={styles.stepHeaders}>
                <img src={safe} alt='safe' />
                <div>
                  <h3>{t('kitoApp.h34')}</h3>
                  <p className='bold'>{t('kitoApp.pBold4')}</p>
                </div>
              </div>
              <p className={styles.stepParagraph}>{t('kitoApp.p4')}</p>
            </div>
          </div>
        </div>
      </div>
      {/* <button className={styles.downloadBtn}>{t('kitoApp.button')}</button> */}
    </section>
  )
}

export default KitoApp;
