import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  BrowserRouter as Router,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import Features from './components/Features/Features';
import KitoApp from './components/KitoApp/KitoApp';
import Testimonials from './components/Testimonial/Testimonials';
import About from './components/About/About';
import MainBelief from './components/MainBelief/MainBelief';
import Contact from './components/Contact/Contact';
import Final from './components/Final/Final';
import Footer from './components/Footer/Footer';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import ScrollToTop from './helpers/ScrollToTop';
import './App.css';


function App() {
  useEffect(() => {
    ReactGA.initialize('UA-225888947-1');
    // to report page view
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route exact path="/" element={
            <>
              <Home />
              <Features />
              <KitoApp />
              <Testimonials />
              <About />
              <MainBelief />
              <Contact />
              <Final />
              <Footer />
            </>
          } />
          <Route exact path="/policy" element={<PrivacyPolicy />} />
          <Route exact path="/terms" element={<TermsAndConditions />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
