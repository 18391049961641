import React from 'react';
import style from './final.module.css';
import { useTranslation } from 'react-i18next';
import carepic from '../../images/finalimg.svg';

function Final() {
  const { t } = useTranslation();

  return (
    <div className={style.finalct}>
      <div className='container'>
        <div className={style.final}>
          <div className={style.care__text}>
            <h4>{t('care.h4')}</h4>
            <h2>{t('care.h1')}</h2>
            {/* <button disabled>{t('care.button')}</button> */}
          </div>
          <div className={style.img_wrapper}>
            <img src={carepic} alt="child making peace sign" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Final