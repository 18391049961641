import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../images/footerlogo.svg";
// import instagram from "../../images/Group.svg";
// import facebook from "../../images/facebook.svg";
// import twitter from "../../images/twitter.svg";
import linkedin from "../../images/linkedin.svg";
import styles from "./footer.module.css";

function Footer() {
  const { t } = useTranslation();
  const date = new Date().getFullYear();

  return (
    <footer>
      <img src={logo} className={styles.footer__logo} alt="logo" />
      <ul className={styles.footer__links}>
        <li>
          <Link target="_blank" to="/terms">
            <p>{t("footer.li1")}</p>
          </Link>
        </li>
        <li>
          <Link target="_blank" to="/policy">
            <p>{t("footer.li2")}</p>
          </Link>
        </li>
      </ul>

      <ul className={styles.footer__socials}>
        {/* <li>
          <a href="">
            <img src={instagram} alt="instagram icon" />
          </a>
        </li>
        <li>
          <a href="">
            <img src={facebook} alt="facebook icon" />
          </a>
        </li> */}
        <li>
          <a href="https://www.linkedin.com/company/kitohealth/" target="_blank" rel="noreferrer">
            <img src={linkedin} alt="linkedin icon" />
          </a>
        </li>
        {/* <li>
          <a href="">
            <img src={twitter} alt="twitter icon" />
          </a>
        </li> */}
      </ul>
      <p className="small">&copy; {date}{t("footer.p")}</p>
    </footer>
  );
}

export default Footer;
