import React from 'react';
import { useTranslation } from 'react-i18next';
import leftQuote from '../../images/leftQuote.svg';
import rightQuote from '../../images/rightQuote.svg';
import styles from './mainBelief.module.css';

function MainBelief() {
  const { t } = useTranslation();
  return (
    <section className={styles.mainBelief}>
      <div className={styles.content}>
        <img src={rightQuote} alt="left apostrophe" />
        <p>{t('mainBelief.p')}</p>
          <img src={leftQuote} className={styles.rightQuote} alt="right apostrophe" />
      </div>
    </section>
  )
}

export default MainBelief;
