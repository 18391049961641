import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import africa from '../../images/ngo.svg';
import ngo from '../../images/africa.svg';
import kid from '../../images/kid.svg';
import styles from './features.module.css';

function Features() {
  const { t } = useTranslation();
  const [dotActive, setDotActive] = useState('dot1');

  useEffect(() => {
    // moveCards('dot2'); //Not working with html {scroll-behavior: smooth;} at index.css
  }, []);

  const onScrollHandler = (e) => {
    const carousel = document.getElementById('carousel');
    const card2 = document.getElementById('card2');;
    const card3 = document.getElementById('card3');
    const card2Left = card2.offsetLeft;
    const card3Left = card3.offsetLeft;
    const carouselMidWidth = carousel.scrollLeft + (window.innerWidth / 2);
    if ( carouselMidWidth < card2Left ) {
      setDotActive('dot1');
    }else if ( carouselMidWidth < card3Left ) {
      setDotActive('dot2');
    }else if ( carouselMidWidth > card3Left ) {
      setDotActive('dot3');
    }
  }

  const moveCards = (dotNumber) => {
    const carousel = document.getElementById('carousel');
    const secondItem = document.getElementById('card2');
    setDotActive(dotNumber)

    switch (dotNumber) {
      case 'dot1':
        // The beginning of carousel
        carousel.scrollLeft = 0;
        break;
      case 'dot2':
        secondItem.scrollIntoView({inline: 'center', block: 'nearest'}) //inline: 'center' because it's a horizontal scroll, block for vertical.
        break;
      case 'dot3':
        // The end of carousel
        carousel.scrollLeft = carousel.scrollWidth;
        break;
      default:
        break;
    }
  }

  return (
    <div className={styles.features} id='features'>
      <div>
        <div className={styles.featuresHeaders}>
          <h4>{t('features.h4')}</h4>
          <h2>{t('features.h2')}</h2>
        </div>
        <div className={styles.carousel} id="carousel" onScroll={onScrollHandler}>
          <div className={`${styles.carousel_item} ${styles.item1}`}>
            <img src={kid} alt='africa' />
            <h3>{t('features.h31')}</h3>
            <p>{t('features.p1')}</p>
          </div>
          <div className={`${styles.carousel_item} ${styles.item2}`} id="card2">
            <img src={ngo} alt='africa' />
            <h3>{t('features.h32')}</h3>
            <p>{t('features.p2')}</p>
          </div>
          <div className={`${styles.carousel_item} ${styles.item3}`} id="card3">
            <img src={africa} alt='ngo' />
            <h3>{t('features.h33')}</h3>
            <p>{t('features.p3')}</p>
          </div>
        </div>
        <div className={styles.featuresDots}>
          <span className={`${styles.featuresDot} ${dotActive === 'dot1'? styles.featuresActiveDot:''}`} onClick={() => moveCards('dot1')}/>
          <span className={`${styles.featuresDot} ${dotActive === 'dot2'? styles.featuresActiveDot:''}`} onClick={() => moveCards('dot2')}/>
          <span className={`${styles.featuresDot} ${dotActive === 'dot3'? styles.featuresActiveDot:''}`} onClick={() => moveCards('dot3')}/>
        </div>
      </div>
      <div className={styles.coloredBar} />
    </div>
  )
}

export default Features;
