import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import logo from '../../images/01.svg';
import menu from '../../images/Menu.svg';
import cross from '../../images/cross.svg';
import styles from './navbar.module.css';

function Navbar() {
  const { i18n, t } = useTranslation();
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const handleNavExpand = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  return (
    <nav className={styles.navContainer}>
      <div className={`${styles.nav} container`}>
        <HashLink to='/#home' >
          <img className={isNavExpanded ? `${styles.hide} ${styles.logo}` : styles.logo} src={logo} alt="KITO logo" />
        </HashLink>

        <div className={styles.menu}>
          {/* {!isNavExpanded && (<button className={styles.downloadBtn}><p>{t('navBar.button')}</p></button>)} */}
          <button className={styles.burgerBtn} onClick={handleNavExpand}>
            {isNavExpanded ? <img width={32} src={cross} alt="close icon" /> : <img width={32} src={menu} alt="menu icon" />}
          </button>
        </div>
        <div className={isNavExpanded ? `${styles.navMenu} ${styles.expanded}` : styles.navMenu}>
          <ul>
            <li><HashLink to="/#features" onClick={handleNavExpand}><p>{t('navBar.li1')}</p></HashLink></li>
            <li><HashLink to="/#howItWorks" onClick={handleNavExpand}><p>{t('navBar.li2')}</p></HashLink></li>
            <li><HashLink to="/#about" onClick={handleNavExpand}><p>{t('navBar.li3')}</p></HashLink></li>
            <li><HashLink to="/#contact" onClick={handleNavExpand}><p>{t('navBar.li4')}</p></HashLink></li>
            {/* <li><button className={`${styles.navMenuBtn} ${styles.downloadBtn}`}><p>{t('navBar.button')}</p></button></li> */}
            <li>
              <button onClick={() => i18n.changeLanguage('Swahili')}>
                <p className={i18n.language === 'Swahili' ? 'bold' : ''}>
                  {t('navBar.language1')}
                </p>
              </button>
              <span> | </span>
              <button onClick={() => i18n.changeLanguage('English')}>
                <p className={i18n.language === 'English' ? 'bold' : ''}>
                  {t('navBar.language2')}
                </p>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar;